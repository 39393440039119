<template>
    <seccion-datos :titulo="tipodesistema" @cambios="guardar($data)">
        <div class="form-group">
            <label>{{ $t('general.conexion') }}</label>
            <select class="custom-select form-control-sm select2" v-model="sistema_compannia">
                <option value="" :selected="sinSistema">{{ $t('general.seleccionesistemaconexion') }}</option>
                <option v-for="sistema in sistemas" :value="sistema.nombre" :key="sistema.id" :selected="sistema.nombre === sistema_compannia" >{{ sistema.nombre }}</option>
            </select>
        </div>
        <!-- Inicio Opciones sistema -->
        <h5>{{ $t('general.opcionesdeconexion') }}</h5>
        <!-- Inicio Opciones web -->
        <div class="row">
            <div class="col-md-12">
                <div class="card card-info card-outline">                                                              
                    <div class="card-body ">
                        <h6>Web</h6>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="codigo_usuario_companyia">{{ $t('general.usuario') }}</label>
                                    <input type="text" class="form-control form-control-sm" id="codigo_usuario_companyia" v-model="codigo_usuario_companyia">
                                </div> 
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="codigo_clave_companyia">{{ $t('general.clave') }}</label>
                                    <input type="text" class="form-control form-control-sm" id="codigo_clave_companyia" v-model="codigo_clave_companyia">
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Opciones web -->
        <!-- Inicio Opciones mail -->
        <div class="row">
            <div class="col-md-12">
                <div class="card card-info card-outline">                                                              
                    <div class="card-body ">
                        <h6>Email</h6>
                        <!-- Inicio mail configuración por correos públicos -->
                        <div class="row">
                            <div class="col-md-12">
                                <label>{{ $t('general.configuraciondecorreo') }}</label>
                                <select class="custom-select form-control-sm select2">
                                    <option>{{ $t('general.seleccioneemail') }}</option>
                                    <option v-for="correo in correos" :key="correo.id" :value="correo.id">{{ correo.nombre_correo }} ({{ correo.direccion_correo }})</option>
                                </select>
                            </div>
                        </div>
                        <!-- Fin mail configuración por correos públicos -->
                        <!-- Si se selecciona un correo, se oculta el formulario de abajo -->
                        <br>
                        <!-- Inicio mail configuración por usuario-clave-servidor -->
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="correo_colaborador_pap">{{ $t('general.usuario') }}</label>
                                    <input type="text" class="form-control form-control-sm" id="correo_colaborador_pap" placeholder="" v-model="correo_colaborador_pap">
                                </div> 
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="correo_pass_colaborador_pap">{{ $t('general.clave') }}</label>
                                    <input type="text" class="form-control form-control-sm" id="correo_pass_colaborador_pap" v-model="correo_pass_colaborador_pap">
                                </div> 
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="servidor_correo_colaborador_pap">{{ $t('general.servidor') }}</label>
                                    <input type="text" class="form-control form-control-sm" id="servidor_correo_colaborador_pap" placeholder="" v-model="servidor_correo_colaborador_pap">
                                </div> 
                            </div>
                        </div>
                        <!-- Fin mail configuración por usuario-clave-servidor -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Opciones mail -->
    </seccion-datos>
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return{
            sistema_compannia: null,
            codigo_usuario_companyia: null,
            codigo_clave_companyia: null,
            correo_colaborador_pap: null,
            correo_pass_colaborador_pap: null,
            servidor_correo_colaborador_pap: null,
            usuario_web: null, 
            clave_web: null, 
            tipodesistema: 'Tipo de sistema',
            listadosistemas:[]
        }
    },
    computed: {
        sinSistema() {
            return this.sistema_compannia === '' 
                    ||
                   this.sistema_compannia === 'ninguno';
        },
        sistemas() {
           // let aux = this.$store.getters['datosApp/sistemas'];
            return this.listadosistemas;
        },
        correos() {
            let aux = this.$store.getters['datosApp/correos'];
            return aux.datos;
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            const componente = this;
            $(function() {
                const $select = $('.select2');
                $select.select2({
                    theme: 'bootstrap4'
                }).select2('val', this.sistema_compannia);
                $select.on('select2:select', function(e) {
                    var data = e.params.data;
                    componente.sistema_compannia = data.id;
                });
            });
            this.tipodesistema = this.$t('general.tipodesistema');
        },
        async cargarsistemas() {
            //GET  modulos/pwgsapi/index.php/sistemas/estado
            const api = new PwgsApi();
            let aux = await api.get('sistemas');
            this.listadosistemas = aux.datos;
            
        }
    },
    created() {
        this.init();
    },
    mounted() {
        this.cargarsistemas();
    }
}
</script>